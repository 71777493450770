<template>
  <div class="cat_login_box">
    <div class="user-wrap flex">
      <div class="game flex">
        <img src="@/assets/images/mycard/game-icon.png" alt="" />
        <span>{{ gameName }}</span>
      </div>
      <div class="user-login" @click="showLogin = true">
        <p>{{ $t("myCard.loginFirst") }}</p>
        <span>{{ $t("toast.gotoGame") }} > </span>
      </div>
    </div>
    <loading v-if="showLoading"></loading>
    <vue-login
      v-if="showLogin"
      @close="close"
      :client_game_app_id="client_game_app_id"
    ></vue-login>

    <template v-if="show">
      <pay-gift-h5
        v-if="isH5"
        ref="payGift"
        :btnDisabled="true"
        :outGiftList="giftList"
      ></pay-gift-h5>
      <pay-gift
        v-else
        ref="payGift"
        :btnDisabled="true"
        :outGiftList="giftList"
      ></pay-gift>
    </template>
  </div>
</template>

<script>
import {
  getCookie,
  getLocalStorage,
  getToken,
  setCrossDomainCookie,
  setLocalStorage,
  setToken,
} from "@/utils/stage";
import vueLogin from "./vue-login";
import payGift from "@/views/MyCard/components/payGift";
import payGiftH5 from "@/views/MyCard/components/payGiftH5";
import { verifyToken } from "@/api/mycard";
import Loading from "../../MyCard/components/loading";

export default {
  components: { payGift, vueLogin, Loading, payGiftH5 },
  data() {
    return {
      checkboxFlog: true,
      storage: [],
      pay: 10,
      projectId: "",
      giftList: [],
      show: false,
      showLogin: false,
      gameName: this.$gameName,
      showLoading: false,
    };
  },
  props: {
    isH5: {
      type: Boolean,
      default: false,
    },
    client_game_app_id: {
      default: "",
      type: String,
    },
  },
  created() {
    if (this.$route.query.access_token) {
      this.verifyToken();
    }
    this.init();
    if (
      this.$route.query.state &&
      this.$route.query.state.indexOf("naver") > -1
    ) {
      this.showLoading = true;
      this.showLogin = true;
    }
    this.$parent.hasLogin = false
  },
  methods: {
    checkbox() {
      this.checkboxFlog = !this.checkboxFlog;
    },
    verifyToken() {
      verifyToken({
        access_token: this.$route.query.access_token,
      }).then((res) => {
        setLocalStorage("loginAccount", res.data.user_entity.username);
        setLocalStorage("accountStorage", JSON.stringify(res.data.user_entity));
        setToken("login", "success", 7);
        setCrossDomainCookie(
          "access_token",
          res.data.user_entity.access_token,
          1
        );
        setCrossDomainCookie("username", res.data.user_entity.username, 1);
        // this.$router.push({ path: "/payOnline" });
        window.location.href = window.location.origin + "/payOnline";
      });
    },
    init() {
      this.showLoading = true;
      setTimeout(() => {
        this.show = true;
        this.showLoading = false;
      }, 2000);
      if (getCookie("username")) {
        setLocalStorage("loginAccount", getCookie("username"));
      }
      if (
        (getToken("login") == "success" && getLocalStorage("accountStorage")) ||
        getCookie("access_token")
      ) {
        window.location.href = window.location.origin + "/payOnline";
      }
    },
    close() {
      this.showLogin = false;
    },
  },
};
</script>
